import axios from 'axios'
import { Auth } from 'aws-amplify'
import { getUserAgent } from './misc'
const restPath = process.env.GATSBY_APIURL

export const getAllFavorites = () => {
  return new Promise((resolve, reject) => {
    try {
      Auth.currentSession()
        .then(function(session) {
          axios
            .get(restPath + '/favorite',
            {headers: {'Content-Type': 'application/json',
            'Authorization': session.idToken.jwtToken }})
            .then(function(favorites) {
              if(favorites.data){
                if(favorites.data.errorType === 'Error'){
                  reject(new Error(favorites.data.errorMessage))
                }
              }else{
                reject(new Error('no favorites returned'))
              }
              resolve(favorites.data)
            })
            .catch(function(err) {
              console.log(err)
              reject(err)
            })
        })
        .catch(function(err) {
          console.log(err)
          reject(err)
        })
    } catch (err) {
      console.log(err)
      reject(err)
    }
  })
}

export const getFavorite = favoriteId => {
  return new Promise((resolve, reject) => {
    try {
      Auth.currentSession()
        .then(function(session) {
          axios
            .get(restPath + '/favorite/' + favoriteId,
            {headers: {'Content-Type': 'application/json',
            'Authorization': session.idToken.jwtToken }})
            .then(function(favorite) {
              if(favorite.data){
                if(favorite.data.errorType === 'Error'){
                  reject(new Error(favorite.data.errorMessage))
                }
              }else{
                reject(new Error('no design returned'))
              }
              resolve(favorite.data)
            })
            .catch(function(err) {
              console.log(err)
              reject(err)
            })
        })
        .catch(function(err) {
          console.log(err)
          reject(err)
        })
    } catch (err) {
      console.log(err)
      reject(err)
    }
  })
}

export const createFavorite = (favorite) => {
  return new Promise((resolve, reject) => {
    try {
      Auth.currentSession()
        .then(function(session) {
          favorite.agentString = getUserAgent()
          axios
            .post(restPath + '/favorite', favorite,
              {headers: {'Content-Type': 'application/json',
              'Authorization': session.idToken.jwtToken }})
            .then(function(favorite) {
              if(favorite.data){
                if(favorite.data.errorMessage){
                  let dataError = new Error(favorite.data.errorMessage)
                  reject(dataError)
                }else{
                  resolve(favorite.data)
                }
              }else{
                let dataError = new Error('Malformed Response')
                reject(dataError)
              }
            })
            .catch(function(err) {
              reject(err)
            })
        })
        .catch(function(err) {
          reject(err)
        })
    } catch (err) {
      reject(err)
    }
  })
}

export const updateFavorite = favorite => {
  return new Promise((resolve, reject) => {
    try {
      Auth.currentSession()
        .then(function(session) {
          favorite.agentString = getUserAgent()
          axios
            .post(restPath + '/favorite/'+favorite._id, favorite,
              {headers: {'Content-Type': 'application/json',
              'Authorization': session.idToken.jwtToken }})
            .then(function(project) {
              if(favorite.data){
                if(favorite.data.errorMessage){
                  reject(favorite.data.errorMessage)
                }else{
                  resolve(favorite.data)
                }
              }else{
                let dataError = new Error('Malformed Response')
                reject(dataError)
              }
            })
            .catch(function(err) {
              reject(err)
            })
        })
        .catch(function(err) {
          reject(err)
        })
    } catch (err) {
      reject(err)
    }
  })
}

export const deleteFavorite = favoriteId => {
  return new Promise((resolve, reject) => {
    try {
      Auth.currentSession()
        .then(function(session) {
          console.log(restPath + '/favorite/'+favoriteId)
          axios
            .delete(restPath + '/favorite/'+favoriteId,
              {headers: {'Content-Type': 'application/json',
              'Authorization': session.idToken.jwtToken }})
            .then(function(favorite) {
              if(favorite.data){
                if(favorite.data.errorMessage){
                  let dataError = new Error(favorite.data.errorMessage)
                  reject(dataError)
                }else{
                  resolve(favorite.data)
                }
              }else{
                let dataError = new Error('Malformed Response')
                reject(dataError)
              }
            })
            .catch(function(err) {
              reject(err)
            })
        })
        .catch(function(err) {
          reject(err)
        })
    } catch (err) {
      reject(err)
    }
  })
}

import React, { Component } from 'react'
import Layout from '../layout-mobile'
import ToolPanel from '../ToolPanel'
import { getProductDownloads } from '../../utils/product-info'
import {
  Alert,
  Button,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'
import DownloadIconWhite from '../../styles/img/download-icon-white.svg'
import CameraIcon from '../../styles/img/camera-icon-white.svg'
import FavoritesIcon from '../../styles/img/favorites-icon-white-1.svg'
import DeleteIcon from '../../styles/img/delete-icon-white.svg'
import Viewer from '../ProductViewer/viewer'
import LightToggle from '../ProductViewer/light-toggle'
import FullScreenButton from '../ProductViewer/full-screen-button'
import LoadingBox from '../loading-box'
import ProductPDF from '../ProductPDF'
import { pdf } from '@react-pdf/renderer'
import saveAs from 'file-saver'
import AccountInfoComplete from '../AccountInfoComplete'
import AddProductBrowser from '../Project/add-product-browser'
import {
  formatPricing,
  mergeHardwareKitsIntoPricing
} from '../../utils/pricing'
import { isLoggedIn } from '../../utils/auth'
import { 
  createDesign,
  getDesign,
  updateDesign,
  deleteDesign,
  getDistinctCustomText } from '../../utils/design'
import {
  buildSayduckSelections,
  initSelections,
  findSelectedLimits,
  filterExternalDocuments,
  getSelectedDimensions,
  getSmartCodeAndDescription,
  selectionsFromProductObject,
  treeSelector, } from '../../utils/selectors'
import {
  createFavorite
} from '../../utils/favorite'
import {
  resetCamera,
  takePhoto,
  toggleLights
} from '../../utils/sayduck'
import {
  getUserInfo
} from '../../utils/user'
import {
  getAllFavorites
} from '../../utils/favorite'
import { 
  isBrowser, navigate, hardNavigate } from '../../utils/misc'
import ArIcon from '../../styles/img/ar-icon.svg'
import {isMobile} from 'react-device-detect'
import QRCode from 'qrcode.react'
var cloneDeep = require('lodash.clonedeep')
const Timeout = 30000
let api;

class Design extends Component {
  constructor(props) {
    super(props)
    this.productViewer=React.createRef()
    this.state = {
      loggedIn: null,
      design: null,
      project: null,
      selectedProduct: null,
      descriptionOverrides: [],
      dimensionOverrides: null,
      lightsOffTriggers: [],
      lightsOn: false,
      favorite: null,
      prevDesign: null,
      productSelected: null,
      errMsg: '',
      product: null,
      selectors: null,
      productInit: null,
      panel1: true,
      panel2: true,
      metaData: null,
      successMsg: '',
      loading: true,
      deleteDialogOpen: false,
      downloadDialogOpen: false,
      showQRCode: false,
      addProductBrowserVisible: false,
      showOnBoard1: false,
      showOnBoard2: false,
      showUserInfoComplete: false,
      userInfo: null,
      myFavorites: [],
      favoriteExists: false,
      customTextVals: [],
      init: true,
      viewerLoaded: false,
      qrText: '',
      ArMsg: false,
      photo: null,
      rendered: false
    }
    if(isBrowser){
      window.addEventListener('sayduck.api-ready', (event) => {
        console.log('sayduck api ready')
        this.renderComplete()
        api = event.detail.instance
      })
    }
  }

  componentDidMount(){
    this.init()
  }

  init = async () => {
    this.handleSetLoading(true)
    this.checkTimeout()
    let user = null
    let designData = null
    user = await isLoggedIn()
    designData = await getDesign(this.props.id)
    let customText = await getDistinctCustomText()

    this.handleGetUserInfo()
    let product = await this.getProduct(designData)
    if(product){
      product.frontmatter = mergeHardwareKitsIntoPricing(product.frontmatter, this.props.hardwareKits)
    }
    let productDefault = designData?.smartCode?.split('-')
    let productInit = initSelections(product.frontmatter, productDefault, designData)
    let selectors = selectionsFromProductObject(productInit)
    let productSelected = product
    let dimensionOverrides = []
    let descriptionOverrides = []
    let metaData = []
    if(productSelected){
      dimensionOverrides = {
        overrides: (productSelected.dimension_overrides ? productSelected.dimension_overrides : [] ),
        overrideIndex: (productSelected.dimension_override_index ? productSelected.dimension_override_index : 1 )
      }
      let descriptionOverrides = []
      if(product.frontmatter.description_overrides){
        descriptionOverrides = product.frontmatter.description_overrides
      }
      let dimensions = getSelectedDimensions(selectors, dimensionOverrides)
      metaData = getSmartCodeAndDescription(selectors, dimensions, descriptionOverrides)
      designData.pricing = formatPricing(metaData, productSelected.frontmatter.pricing_options, designData.quantity)
    }
    let lightsOffTriggers = []
    if(product?.lights_off_triggers){
      if(product.lights_off_triggers.length > 0){
        lightsOffTriggers = product.lights_off_triggers
      }
    }
    let limits = findSelectedLimits(selectors)
    if(product?.frontmatter?.uses_light){
      await toggleLights(api)
    }
    if(isBrowser){
      window.document.addEventListener("sayduck.viewer.gltf.loaded", this.renderComplete, false)
    }
    this.setState({
      loggedIn: user,
      design: designData,
      metaData: metaData,
      prevDesign: designData,
      product: product,
      productInit: productInit,
      selectors: selectors,
      productSelected: productSelected,
      descriptionOverrides: descriptionOverrides,
      dimensionOverrides: dimensionOverrides,
      lightsOffTriggers: lightsOffTriggers,
      limits: limits,
      customTextVals: customText
    }, function(){
      this.getMyFavorites()
    })
    if(!designData.smartDescription.length){
      setTimeout(function(){
        this.handleSaveDesign()
      }.bind(this), 2000)
      
      this.setState({
        init: false
      })
    }else{
      this.setState({
        init: false
      })
    }
  }

  checkTimeout = () => {
    setTimeout(() => {
      if(!this.state.viewerLoaded){
        this.setState({
          timeoutMessage: true
        })
      }
    }, Timeout);
  }

  getMyFavorites = () => {
    getAllFavorites()
    .then((favorites)=>{
      let favoriteExists = null
      if(this.state?.design?.smartCode){
        let findFavorite = favorites.find((item)=>{
          return item.smartCode === this.state.design.smartCode
        })
        if(findFavorite){
          favoriteExists = true
        }
      }
      this.setState({
        myFavorites: favorites,
        favoriteExists: favoriteExists
      })
    })
    .catch((err)=>{
      this.setState({
        errMsg: err.toString()
      })
    })
  }

  getProduct = async (design) => {
    let smartCode = design.smartCode
    let smartPrefix = (smartCode.split('-').length > 0 ? smartCode.split('-')[0] : '')
    let foundProduct = this.props.products.find((product)=>{
      return product.node.childMarkdownRemark.frontmatter.configurator_id === design.configuratorId
    })
    if(foundProduct){
      foundProduct = foundProduct.node.childMarkdownRemark
    }else{
      foundProduct = this.props.products.find(product=>{
        return product.node.childMarkdownRemark.frontmatter.smart_code === smartPrefix
      })
      if(foundProduct){
        foundProduct = foundProduct.node.childMarkdownRemark
      }
    }
    if(foundProduct){
      let foundProductConfig = this.props.productConfigs.find((config)=>{
        return config.node.childMarkdownRemark.frontmatter.configurator_id === foundProduct?.frontmatter?.configurator_id
      })
      if(foundProductConfig){
        foundProduct.frontmatter.configurator_components = foundProductConfig.node.childMarkdownRemark.frontmatter.configurator_components
        foundProduct.frontmatter.description_overrides = foundProductConfig.node.childMarkdownRemark.frontmatter.description_overrides
        foundProduct.frontmatter.dimension_override_index = foundProductConfig.node.childMarkdownRemark.frontmatter.dimension_override_index
        foundProduct.frontmatter.dimension_overrides = foundProductConfig.node.childMarkdownRemark.frontmatter.dimension_overrides
        foundProduct.frontmatter.lights_off_triggers = foundProductConfig.node.childMarkdownRemark.frontmatter.lights_off_triggers
        foundProduct.frontmatter.shadow_id = foundProductConfig.node.childMarkdownRemark.frontmatter.shadow_id
        foundProduct.frontmatter.shadow_off_id = foundProductConfig.node.childMarkdownRemark.frontmatter.shadow_off_id
        foundProduct.frontmatter.shadows = foundProductConfig.node.childMarkdownRemark.frontmatter.shadows
        foundProduct.frontmatter.uses_light = foundProductConfig.node.childMarkdownRemark.frontmatter.uses_light
        foundProduct.frontmatter.use_updated_viewer = foundProductConfig.node.childMarkdownRemark.frontmatter.use_updated_viewer
      }
    }
    return foundProduct
  }

  updateLoggedIn = (userData) => {
    this.setState({
      loggedIn: userData
    })
  }

  handleSaveDesign = async () => {
    try{
      this.setState({
        loading: true,
        successMsg: 'Uploading your design. Please wait.'
      })
      resetCamera(api)
      let photo = await takePhoto(api)
      let design = {
        _id: this.state.design._id,
        smartCode: this.state.metaData.smartCode.join('-'),
        smartDescription: [...this.state.metaData.smartDescription],
        name: this.state.design.name,
        productClass: this.state.design.productClass,
        configuratorId: this.state.design.configuratorId,
        dimensions: this.state.metaData.dimensions,
        quantity: this.state.design.quantity,
        notes: this.state.design.notes,
        file: photo.dataUrl
      }
      design.pricing = formatPricing(this.state.metaData, this.state.productSelected.frontmatter.pricing_options, design.quantity)
      let data = await updateDesign(design)
      this.setState({
        successMsg: 'Design Saved',
        loading: false,
        prevDesign: design
      })
    }catch (err) {
      this.setState({
        errMsg: err.toString(),
        loading: false
      })
    }

  }

  handleCloseError = () => {
    this.setState({
      errMsg: ''
    })
  }

  handleCloseSuccess = () => {
    this.setState({
      successMsg: ''
    })
  }

  handleUpdateMetaData = async (metaData) => {
    let design = {...this.state.design}
    design.smartCode = metaData.smartCode.join('-')
    design.smartDescription = [...metaData.smartDescription]
    if(this.state.productSelected){
      design.pricing = formatPricing(metaData, this.state.productSelected.node.frontmatter.pricing_options, design.quantity)
    }
    let photo = await takePhoto(api)
    this.setState({
      metaData: {...metaData, smartDescription: design.smartDescription},
      design: design,
      photo: photo
    })
  }

  handleUpdateQuantity = (quantity) => {
    let design = {...this.state.design}
    design.quantity = quantity
    this.setState({
      design: design
    })
  }

  handleUpdateQuantityChild = (quantity) => {
    let design = {...this.state.design}
    design.quantity = quantity
    this.setState({
      design: design
    })
    this.productViewer.current.updateQuantity(quantity)
  }

  handleUpdateNotes = (notes) => {
    let design = {...this.state.design}
    design.notes = notes
    this.setState({
      design: design
    })
  }

  handleSetLoading = (loading) => {
    this.setState({
      loading: loading
    })
  }

  handleGoToProductPage = () => {
    if(isBrowser){
      window.open(this.state.product.frontmatter.website_link);
    }
  }

  handleDialogClose = () => {
    this.setState({
      deleteDialogOpen: false,
      downloadDialogOpen: false,
      addProductBrowserVisible: false
    })
  }

  handleShowProductBrowser = () => {
    this.setState({
      addProductBrowserVisible: true
    })
  }

  handleSelectProduct = (product) => {
    this.setState({
      selectedProduct: product
    })
  }

  handleAddProduct = () => {
    if(this.state.selectedProduct){
      this.handleSetLoading(true)
      let product = this.state.selectedProduct
      let smartCode = product.frontmatter.default_configuration
      let design = {
        smartCode: smartCode,
        smartDescription: [],
        name: product.frontmatter.product_name,
        productClass: product.frontmatter.product_class,
        dimensions: '',
        configuratorId: product.frontmatter.configurator_id,
        project: this.state.design.project,
        file: ''
      }
      createDesign(design)
      .then((designData) => {
        this.handleSetLoading(false)
        hardNavigate('/design/'+designData._id + '?new=true')
      })
      .catch((err) => {
        this.handleSetLoading(false)
        this.setState({
          errMsg: err.message
        })
      })
    }
  }

  handleGetUserInfo = (callbackFunction) => {
    getUserInfo()
    .then(info=>{
      this.setState({
        userInfo: info
      }, function(){
        if(callbackFunction){
          callbackFunction()
        }
      })
    })
    .catch(err=>{
      this.setState({
        errMsg: err.toString()
      })
    })
  }

  handleDelete = () => {
    let projectId = this.state.design.project
    this.setState({
      loading: true
    })
    deleteDesign(this.state.design._id)
    .then(function(data){
      this.setState({
        deleteDialogOpen: false,
        loading: false
      }, function(){
        navigate('/project/'+projectId)
      })
    }.bind(this))
    .catch(function(err){
      this.setState({
        errMsg: err.toString(),
        loading: false
      })
    })
  }

  downloadFile = async (filename) => {
    if(isBrowser){
      let response = await fetch(filename, {
        mode: 'cors'
      })
      return await response.blob()
    }else{
      return null
    }
  }

  handleCreateFavorite = async () => {
    this.handleSetLoading(true)
    let photo = await takePhoto(api)
    let design = {
      _id: this.state.design._id,
      smartCode: this.state.metaData.smartCode.join('-'),
      smartDescription: [...this.state.metaData.smartDescription],
      name: this.state.design.name,
      productClass: this.state.design.productClass,
      configuratorId: this.state.design.configuratorId,
      dimensions: this.state.metaData.dimensions,
      quantity: this.state.design.quantity,
      notes: this.state.design.notes,
      file: photo.dataUrl
    }
    await createFavorite(design)
    this.handleSetLoading(false)
    this.setState({
      successMsg: 'Favorite Saved.'
    })
  }

  updateData = (object, previousSelection) => {
    let selectors = cloneDeep(this.state.selectors)
    let dimensionOverrides = cloneDeep(this.state.dimensionOverrides)
    let descriptionOverrides = cloneDeep(this.state.descriptionOverrides)
    let documents = []
    this.handleSetLoading(true)
    if(!api){
      window.document.addEventListener("sayduck.viewer.gltf.loaded", this.renderComplete, false)
    }else{
      this.renderComplete()
      // window.document.addEventListener("sayduck.editor.gltfLoaded", this.renderComplete, false)
    }
    treeSelector(selectors, dimensionOverrides, descriptionOverrides, object, previousSelection)
    .then(function(selectorData){
      documents = filterExternalDocuments(this.state.product.external_documents, selectorData.metaData)
      let design = cloneDeep(this.state.design)
      design.smartCode = selectorData.metaData.smartCode.join('-')
      design.dimensions = selectorData.dimensions?.dimensions
      design.pricing = formatPricing(selectorData.metaData, this.state.productSelected.frontmatter.pricing_options, design.quantity)
      this.setState({
        selectors: selectorData.selectors,
        limits: selectorData.limits,
        documents: documents,
        metaData: {...selectorData.metaData, smartDescription: [...selectorData.metaData.smartDescription]},
        dimensions: selectorData.dimensions,
        design: design
      }, function(){
        let details = buildSayduckSelections(selectorData.selectors)
        delete details[this.state.productSelected.frontmatter.configurator_id]
        this.setState({
          details: details
        }, function(){
          if(this.state.lightsOffTriggers.length > 0) {
            this.state.lightsOffTriggers.forEach((item, i) => {
              let itemIndex = parseInt(item.smart_code_position)
              if(selectorData.metaData.smartCode[itemIndex] === item.smart_code){
                if(this.state.lightsOn){
                  this.toggleLights(false)
                }
              }
            })
          }
          if(this.props.updateMetaData){
            this.props.updateMetaData(selectorData.metaData)
          }
          for (var key in details) {
            try{
              api.configurator.setActiveByUuid(details[key])
            }catch(err){
              console.log(err)
            }
          }
          window.dispatchEvent(
            new CustomEvent(
              'sayduck.configurator.actions.updateSelectedConfiguration', {detail: details}
            )
          )
          if(selectorData.resetCamera){
            setTimeout(function(){
              resetCamera(api)
            },500)
          }
        }.bind(this))
      }.bind(this))
    }.bind(this))
  }

  renderComplete = (e) => {
    if(!this.state.viewerLoaded){
      setTimeout(function(){
        if(api){
          resetCamera(api)
        }
      },500)
    }
    this.setState({
      viewerLoaded: true
    })
    if(!this.state.init){
      this.handleSetLoading(false)
    }
    if(isBrowser){
      window.document.removeEventListener("sayduck.viewer.gltf.loaded", this.renderComplete)
    }
  }

  downloadFromUrl = async(url, originalFilename) => {
    const link = document.createElement('a');
    link.download =  originalFilename.replace(/[^a-zA-Z0-9 ._-]/g, '');
    link.href = url;
    link.click()
  }

  takePhoto = async () => {
    this.handleSetLoading(true)
    let fileName = this.state.product?.frontmatter?.product_name.toLowerCase().replaceAll(' ','-')
    let photo = await takePhoto(api, true)
    const link = document.createElement('a');
    link.download = fileName + '.png';
    link.href = photo.dataUrl
    link.click()
    this.handleSetLoading(false)
  }

  handleOpenLink = (link) => {
    if(isBrowser){
      window.open(link);
    }
  }

  launchWebAR = () => {
    if(isBrowser){
      if(api){
        if(isMobile){
          this.setState({
            ArMsg: true
          })
          api.scene.launchWebAr()
          this.handleSetLoading(false)
        }else{
          api.scene.getQrCodeUrl()
          .then(url => {
            this.setState({
              showQRCode: true,
              qrText: url
            })
            this.handleSetLoading(false)
          })
        }
      }else{
        var element = document.getElementsByClassName('sayduck-3d-viewer-164')[0]
        if(element){
          this.handleSetLoading(true)
          element.click()
          this.handleSetLoading(false)
          if(isMobile){
            this.setState({
              ArMsg: true
            })
          }
        }
      }
    }
  }

  handleCloseArMessage = () => {
    this.setState({
      ArMsg: false
    })
  }

  handleSetLights = (lights) => {
    this.setState({
      lightsOn: lights
    }, function(){
      this.updateDetails(this.state.details, this.state.metaData, this.state.dimensions, this.state.selectors)
    })
  }

  toggleLights = (lightsOn) => {
    if (isBrowser) {
      setTimeout(function(){
        let details = {...this.state.details}
        let shadowSelectors = null
        if(this.state.product?.frontmatter?.shadow_id){
          shadowSelectors  = {
            id: this.state.product.frontmatter.shadow_id,
            off_id: this.state.product.frontmatter.shadow_off_id,
            shadows: this.state.product.frontmatter.shadows
          }
        }
        if(shadowSelectors && !lightsOn){
          let shadow_id = shadowSelectors.id
          details[shadow_id] = shadowSelectors.off_id
        }else if(shadowSelectors && lightsOn){
          let shadow_id = shadowSelectors.id
          details[shadow_id] = shadowSelectors.shadows[0].configurator_id
        }
        this.setState({
          lightsOn: lightsOn,
          details: details
        }, function(){
          if(isBrowser){
            window.dispatchEvent(
              new CustomEvent(
                'sayduck.configurator.actions.updateSelectedConfiguration', {detail: details}
              )
            )
          }
        })
      }.bind(this),1000)
      if(api){
        api.scene.toggleLights()
      }
      window.dispatchEvent(
        new CustomEvent(
          'sayduck.viewer.actions.toggleSwitchableLights', null
        )
      )
    }
  }

  getDownloads = async() => {
    if(this.state.product.frontmatter.website_link){
      let downloads = await getProductDownloads(this.state.product.frontmatter.website_link);
      console.log(downloads, this.state.product)
      this.setState({
        product : {...this.state.product, downloads: downloads}
      })
    }
  }

  downloadPDF = async () => {
    let fileName = this.state?.product?.frontmatter?.product_name.toLowerCase().replaceAll(' ','-')
    if(isBrowser){
      this.handleSetLoading(true)
      let photo = await takePhoto(api)
      const productPDF = <ProductPDF
      designID={this.state?.design?._id}
      pricingOptions={this.state.product.frontmatter.pricing_options}
      productMeta={this.state.metaData}
      notes={this.state.design.notes}
      image={photo.dataUrl}
      quantity={this.state.design.quantity}
      />
      let blob = await pdf(productPDF).toBlob()
      this.handleSetLoading(false)
      var pdfURL = window.URL.createObjectURL(blob)
      let tempLink = document.createElement('a')
      tempLink.href = pdfURL
      tempLink.setAttribute('download', fileName + '.pdf')
      tempLink.click()
    }
  }

  zipAllFiles = async () => {
    let fileName = this.state?.product?.frontmatter?.product_name.toLowerCase().replaceAll(' ','-')
    this.handleSetLoading(true)
    let allFiles = []
    if(isBrowser){
      let photo = await takePhoto(api)
      const productPDF = <ProductPDF
          designID={this.state?.design?._id}
          pricingOptions={this.state.product.frontmatter.pricing_options}
          productMeta={this.state.metaData}
          notes={this.state.design.notes}
          image={photo.dataUrl}
          quantity={this.state.design.quantity}
          />
        let blob = await pdf(productPDF).toBlob()
        this.handleSetLoading(false)

        var pdfURL = window.URL.createObjectURL(blob)
        allFiles.push({
          productName: this.state.product.frontmatter.product_name,
          configuratorId: this.state.product.frontmatter.configurator_id,
          name: 'Specification Sheet',
          originalFilename: 'specification-sheet-' + this.state.product.frontmatter.product_name.replace(/[^a-zA-Z0-9 ._-]/g, '') + '.pdf',
          url: pdfURL
        })

        if(this.state.product.downloads){
          this.state.product.downloads.forEach((category)=>{
            category.files.forEach((file)=>{
              allFiles.push({
                productName: this.state.product.frontmatter.product_name,
                configuratorId: this.state.product.frontmatter.configurator_id,
                originalFilename: file.file.originalFilename,
                name: file.title,
                url: file.file.url + "?dl="
              })
            })
          })
        }
        const zip = require('jszip')()
        for (let file = 0; file < allFiles.length; file++) {
          // Zip file with the file name.
          let fileUrl = allFiles[file].url
          let fileName = allFiles[file].originalFilename
          let response = await this.downloadFile(fileUrl)
          zip.file(fileName, response);
        }
        let outputFileName = fileName + '.zip'
        zip.generateAsync({type: "blob"}).then(content => {
          this.handleSetLoading(false)
          saveAs(content, outputFileName);
        });
    }
  }

  launchWebsite = () => {
    let url = this.state?.product?.frontmatter?.website_link
    if(isBrowser){
      window.open(url, '_blank')
    }
  }

  render() {
    let context = null
    let shadowSelectors = null
    let lightToggleButton = null
    if(this.state.design && this.state.product && this.state.productInit){
      context = {
        id: this.state.product.frontmatter.configurator_id,
        frontmatter: this.state.product.frontmatter,
        product: this.state.productInit,
        selectors: this.state.selectors,
        hardware_kits: this.props.hardwareKits,
        swatches: this.props.swatches
      }
      if(context.product.shadow_id){
        shadowSelectors  = {
          id: context.product.shadow_id,
          off_id: context.product?.shadow_off_id,
          shadows: context.product.shadows
        }
      }
      if(this.state.product.frontmatter.uses_light){
        lightToggleButton = <LightToggle
        lightsOn={this.state.lightsOn}
        api={api}
        setLights={
          this.toggleLights
        }/>
      }
    }
    let projectId = this.state?.design?.project
    let designName = this.state?.design?.name
    let downloadButtons = []
    if(this.state.product?.downloads){
      let documents = this.state.product?.downloads
      if(documents.length > 0){
        documents.forEach((document)=>{
          document.files.forEach((file)=>{
            downloadButtons.push(<Button key={file._key} className='download-button' onClick={()=>{this.downloadFromUrl(file.file.url + "?dl=1" + file.file.originalFilename.replace(/[^a-zA-Z0-9 ._-]/g, ''), file.file.originalFilename)}}>{file.title}</Button>)
          })
        })
      }



    }
    downloadButtons.unshift(
      <Button onClick={this.downloadPDF} key={1000001} className='download-button'>
      Specification Sheet
      </Button>
    )
    let functions = <>
    <Button onClick={this.handleSaveDesign} variant="contained" title="Save Design">
      Save
    </Button>
    <IconButton title="Design Downloads" onClick={()=>{this.getDownloads(); this.setState({downloadDialogOpen: true});}}>
      <img src={DownloadIconWhite} alt='Design Downloads' />
    </IconButton>
    <IconButton onClick={this.takePhoto} title="Take a Screenshot">
      <img src={CameraIcon} alt='Take a Screenshot' />
    </IconButton>
    <IconButton onClick={this.handleCreateFavorite} title="Add to Favorites">
      <img src={FavoritesIcon} alt='Add to Favorites' />
    </IconButton>
    <IconButton onClick={()=>{this.setState({deleteDialogOpen: true})}} title="Delete this Design">
      <img src={DeleteIcon} alt='Delete this Design' />
    </IconButton>
    <IconButton onClick={this.handleShowProductBrowser} title="Add a new Product">
      <AddIcon />
    </IconButton>
    </>

    return(
      <Layout titleText={designName ? designName : 'Design'}
      showMenu={false}
      className='no-pad-mobile'
      toolFunctions={functions}
      navigateBack={()=>{
        navigate('/project/'+projectId)
      }}>
      {this.state.loading &&
        <LoadingBox />
      }
      {context &&
        <section className='design-wrapper'>
          <Viewer
            id={context.id}
            product={context.product}
            selectors={context.selectors}
            lightsOn={this.state.lightsOn}
            shadowSelectors={shadowSelectors}
          >
            <div className='viewer-buttons'>
              {lightToggleButton}
              <IconButton onClick={this.launchWebAR}>
                <img src={ArIcon} alt='launch web ar' />
              </IconButton>
              <FullScreenButton toggleFullScreen={()=>{
                this.setState({
                  isFullScreen: !this.state.isFullScreen
                })
              }} />

            </div>
          </Viewer>
          <ToolPanel title={this.state?.design?.name}
            quantity={this.state?.design?.quantity}
            selectors={context.selectors}
            limits={this.state?.limits}
            swatches={context.swatches}
            handleUpdateQuantity={this.handleUpdateQuantity}
            updateData={this.updateData}
            handleUpdateNotes={this.handleUpdateNotes}
            design={this.state.design}
            product={this.state.product}
            customTextVals={this.state.customTextVals}
            />
        </section>
      }
      <Snackbar open={(this.state.errMsg ? true : false)} autoHideDuration={6000} onClose={this.handleCloseError}>
        <Alert elevation={6} variant="filled" severity="error" onClose={this.handleCloseError}>
          {(this.state.errMsg ? this.state.errMsg : '')}
        </Alert>
      </Snackbar>
      <Snackbar open={(this.state.successMsg ? true : false)} autoHideDuration={6000} onClose={this.handleCloseSuccess}>
        <Alert elevation={6} variant="filled" severity="success" onClose={this.handleCloseSuccess}>
          {(this.state.successMsg ? this.state.successMsg : '')}
        </Alert>
      </Snackbar>
      <Dialog
        open={this.state.deleteDialogOpen}
        className='status-alert'
        onClose={this.handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete this Design?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action is permanent.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleDialogClose}>Cancel</Button>
          <Button onClick={this.handleDelete} variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={this.state.downloadDialogOpen}
        onClose={this.handleDialogClose}
        fullWidth={true}
        maxWidth={'xs'}
        >
          <DialogTitle>
          <IconButton onClick={this.handleDialogClose}>
              <CloseIcon />
          </IconButton>
            <span>Product Specifications</span></DialogTitle>
          <DialogContent>
            <section className='downloads-list'>
              {downloadButtons}
            </section>
          </DialogContent>
          <DialogActions>
          <Button variant="contained"
          onClick={this.zipAllFiles}>
          Download All
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        maxWidth={false}
        open={this.state.addProductBrowserVisible}
        onClose={(e)=>{
          this.setState({addProductBrowserVisible: false})
        }}>
        <DialogTitle>
          <IconButton onClick={this.handleDialogClose}>
              <CloseIcon />
          </IconButton>
        <span>Add a Product</span>
        </DialogTitle>
        <DialogContent sx={{display: 'flex', overflow: 'hidden', backgroundColor: '#e6e6e6'}}>
          <AddProductBrowser
          products={this.props.products}
          handleSetLoading={this.handleSetLoading}
          onSelect={this.handleSelectProduct}
          onComplete={()=>{
            this.setState({addProductBrowserVisible: false})
          }} />
        </DialogContent>
        <DialogActions sx={{backgroundColor: '#e6e6e6'}}>
        <Button className="underline-button"
        onClick={(e)=>{
          this.setState({addProductBrowserVisible: false})
        }}>
        Cancel
        </Button>
        {this.state.selectedProduct &&
                <Button variant="contained" onClick={this.handleAddProduct} className='no-overflow'>
                Add {this.state.selectedProduct.frontmatter.product_name}
              </Button>
        }
        </DialogActions>
      </Dialog>
      <Dialog
        open={this.state.showQRCode}
        maxWidth={'xs'}
        onClose={()=>{this.setState({showQRCode: false})}}>
        <DialogTitle>
          {'Scan the QR code with your mobile camera for augmented reality'}
        </DialogTitle>
        <DialogContent>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '1rem'
          }}>
          <QRCode value={this.state.qrText} renderAs='svg' size='300' />
          <p style={{textAlign: 'center'}}>To see the model in augmented reality you need an iOS or Android AR supported device.</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{this.setState({showQRCode: false})}}>Close</Button>
        </DialogActions>
      </Dialog>
      {this.state.showUserInfoComplete &&
        <AccountInfoComplete
          userInfo={this.state.userInfo}
          handleSetLoading={this.handleSetLoading}
          handleSetError={(err)=>{
            this.setState({errMsg: err.toString})
          }}
          handleSetSuccess={(success)=>{
            this.setState({successMsg: success})
          }}
          onComplete={(e)=>{
            this.setState({
              loading: false,
              showUserInfoComplete: false
            })
            this.handleGetUserInfo(function(){
              this.zipAllFiles()
            }.bind(this))
          }}
          handleCancel={(e)=>{
            this.setState({
              showUserInfoComplete: false
            })
          }}
        />
      }
      <Snackbar open={(this.state.timeoutMessage ? true : false)} onClose={()=>{this.setState({timeoutMessage: false})}}>
        <Alert elevation={6} variant="filled" severity="error" onClose={()=>{this.setState({timeoutMessage: false})}} sx={{color: '#fff'}}>
          <span style={{color: '#fff'}}>
            Our 3D Viewer component is either experiencing an outage or longer than usual load times. Please visit our <a style={{color: '#fff'}} href='https://formssurfaces.instatus.com/' target="_blank" rel="noreferrer">status page</a> or contact us at <a style={{color:'#fff'}} href="mailto:support@forms-surfaces.com">support@forms-surfaces.com</a>
          </span>
        </Alert>
      </Snackbar>
      <Snackbar open={(this.state.ArMsg ? true : false)} autoHideDuration={6000} onClose={this.handleCloseArMessage}>
        <Alert elevation={6} variant="filled" severity="warning" onClose={this.handleCloseArMessage}>
          AR Model is downloading. Please wait...
        </Alert>
      </Snackbar>
      </Layout>
    )
  }
}

export default Design
